export const domChange = function(node) {
    return (key, value, img) => {
        if (img) {
            node[key] = value;
            return;
        }
        node.style[key] = value;
    }
}

export function HTMLDecode(text) { 
    var temp = document.createElement("div"); 
    temp.innerHTML = text; 
    var output = temp.innerText || temp.textContent;
    output = output.replace(/【([^】]+)】/g, '<span style="color:red;font-weight:600">【$1】</span>')
    temp = null; 
    return output; 
}