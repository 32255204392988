import React from 'react';
import { connect } from 'react-redux';
import BaseIcon from 'assets/svgIcon/baseIcon';

const LiSingle = (props) => {
  const { question,answer,index,num,dispatch,quizType } = props;
  const chooseQuizIndex = () => {
    dispatch({
      type: 'CHANGE_QUESTION_INDEX',
      quizType,
      quizTypeIndex: index,
    })
  }
  return (
    <section>
      <style jsx>
        {`
          .question {
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-bottom: 8px;
          }
          .title {
            margin-left: 8px;
            font-size: 16px;
          }
          .question-content {
            padding: 15px 20px;
            white-space: pre-wrap;
            display: ${num === index ? 'block' : 'none'};
            font-weight: bold;
            background: #f8f8f8;
          }
        `}
      </style>
      <div className="question add-pointer" onClick={chooseQuizIndex}>
        <BaseIcon iconName="QuestionCircle" width={15} height={15} />
        <div className="title">{question}</div>
      </div>
      <div className="question-content">
        {answer}
      </div>
    </section>
  )
}

const Recharge = (props) => {
    const { questions,type,questionActivesIndex } = props;
    // const [num,setNum] = useState(0);
    const style = {
      width: '100%',
      boxSizing: 'border-box',
      minHeight: '460px',
      background: 'white',
    }
    return (
      <section style={style}>
         <style jsx>{`
             .quiz {
               margin-top: 15px;
             }
         `}
         </style>
         <ul className="content">
            {questions.map((item,index) => {
              return (<li className="quiz" key={index}>
                  <LiSingle 
                    question={item.question} 
                    answer={item.answer} 
                    index={index} 
                    num={questionActivesIndex[type]}
                    dispatch={props.dispatch}
                    quizType={type}
                  />
                </li>)
            })}
         </ul>
      </section>
    )
}
                          
function mapStateToProps(state) {
  return {
      questionActivesIndex: state.questionReducer.questionActivesIndex
  }
}

export default connect(mapStateToProps)(Recharge)