import React, {useState, useEffect} from 'react';
import { fetchData } from 'helpers';

const AccountContent = (props) => {
    const id = new URLSearchParams(props.location.search).get('fid');
    const style = {
        minHeight: '495px',
        border: '1px solid #e9e9e9',
        padding: '30px 17px',
        background: 'white',
    }
    const [data, setData] = useState({});
    useEffect(() => {
        fetchData('getQuestionStaticDetail', {id,}).then((res) => {
            setData(res.data)
        })
    },[id])
    return (
        <section style={style}>
            <style>
                {`
                    .account-content img {
                        // width: 255px;
                        // height: 200px;
                        margin-left: 50%;
                        transform: translateX(-50%);
                    }
                `}
            </style>
            <style jsx>{`
                .title {
                    text-align: center;
                    font-size: 18px;
                    margin-bottom: 20px;
                }
                .account-content {
                    line-height: 24px;
                }
            `}
            </style>
            <h4 className="title">{data.title}</h4>
            <div className="account-content" dangerouslySetInnerHTML={{__html: data.content}} />
        </section>
    )
}

export default AccountContent