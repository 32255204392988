import React from 'react'
import { connect } from 'react-redux';


// 快速引导
const QuickBoot = (props) => {
    const { title, content, activeKey,quizType } = props;
    const chooseQuiz = (index) => {
        props.dispatch({
            type: 'CHANGE_QUESTION_INDEX',
            quizType,
            quizTypeIndex: index,
        })
        props.dispatch({
            type: 'CHANGE_QUESTION_TYPE',
            activeKey,
        })
    }
    const style = {
        width: '436px',
        // height: '132px',
        border: '1px solid #e9e9e9',
        paddingTop: '10px',
        marginBottom: '12px',
        // marginRight: '12px',
    }
   return (
     <section style={style}>
        <style jsx>{`
            .title {
                color: #e46309;
                font-weight: bold;
                padding-left: 15px;
                margin-bottom: 8px;
                font-size: 16px;
            }
            .single {
                position: relative;
                padding-left: 26px;
                margin-bottom: 8px;
            }
            .circle {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #ccc;
                position: absolute;
                top: 50%;
                left: 15px;
                transform: translateY(-50%);
            }
            .content {
                font-size: 14px;
            }
        `}
        </style>
        <p className="title">{title}</p>
        <ul className="list">
           {content.map((item,index) => {
               return ( 
                <li className="single" key={index} onClick={() => chooseQuiz(index)}>
                    <p className="circle"></p>
                    <p className="content add-pointer">{item.question}</p>
                </li>
            )
           })}
        </ul>
     </section>
   )
}

function mapStateToProps(state) {
    return {
        // questionIndex: state.questionReducer.questionIndex
    }
}

export default connect(mapStateToProps)(QuickBoot)