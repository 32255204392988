import React from 'react';
import { fetchData } from '../helpers';


const qbHoc = WrappedComponent => class extends React.Component {
    render() {
        return  <WrappedComponent {...this.props} />
    }

    componentWillMount(){
        fetchData('getQuestionCateList').then((res) => {
            let breads = res.data.map(item => {
                return {
                    id: item.id,
                    title: item.title
                }
            })
            breads.toJSON = undefined;
            window.sessionStorage.setItem('questionBreads', JSON.stringify(breads))
        }).catch((err) => {

        });
    }
}


export default qbHoc;