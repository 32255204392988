import React from 'react';
import { Table } from 'antd';
import Zmage from 'react-zmage'


const columns = [
    {
      title: '',
      className: 'column-question-td1',
      dataIndex: 'label',
      colSpan: 2,
      width: 199,
    },
    {
      title: 'value',
      className: 'column-question-td2',
      colSpan: 0,
      dataIndex: 'value',
      render: (value) => {
        const style = {
            display: 'flex',
            alignItems: 'center'
        }
        if (Array.isArray(value)) {
            // value.push(value[0]);
            const imgSets = value.map(item => {
                return {
                    src: item.url,
                    alt: item.name,
                    className: 'zimageClass'
                }
            });
            return  <ul style={style}>
                        {
                            value.map((item, index) => {
                                return <li style={{marginRight: '10px'}} key={index}>
                                  {item.type === 'file'?<div>{item.name}</div>:<Zmage
                                    src={item.url}
                                    set={imgSets}
                                    // onBrowsing={onBrowsing}
                                    // backdrop="rgba(0, 0, 0,0.6)"
                                    controller={{
                                      download: false,
                                      zoom: false,
                                    }}
                                    edge={200}
                                    defaultPage={index}
                                    alt=""/>}
                                </li>
                            })
                        }
                    </ul>
        } else {
            return value
        }
      }
    },
  ];

const QuizInfo = (props) => {
    const { quizDetail, title } = props;
    columns[0].title = title;
    return (
      <section>
        <style>
            {`
            .column-question-td1 {
                padding: 8px 12px !important;
                text-align: left !important;
                height: 37px;
                background: #f4f4f4 !important;
            }
            .column-question-td2 {
                padding: 8px 12px !important;
                height: 37px;
                background: white;
            }
            .column-question-td2 img{
                width: 60px;
                height: 60px;
            }
            .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
                background: white;
            }
            .ant-pagination-item:focus, .ant-pagination-item:hover {
                border-color: #D0021B;
                color: #D0021B;
              }
              .ant-pagination-item:focus a, .ant-pagination-item:hover a {
                color: #D0021B;
              }
              .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
                border-color: #D0021B;
                color: #D0021B;
              }
            #zmageBackground {
                background: rgba(0,0,0,0.6) !important;
            }
            #zmageControlFlipLeft {
                left: 20%;
                background: white;
            }
            #zmageControlFlipRight {
                right: 20%;
            }
            #zmageControl {
                right: 50%;
                // top: 100px;
                transform: translateX(50%);
            }
            // #zmageControlPagination {
            //     bottom: 100px;
            // }
            .zimageClass {
                width: 800px;
                height: auto;
                display: block;
                border: 10px solid white;
            }
            `}
        </style>
        <style jsx>{`
            
        `}
        </style>
        <Table
            columns={columns}
            rowKey={record => record.key}
            dataSource={quizDetail}
            bordered
            pagination={false}
            locale={{
                emptyText: ' '
            }}
        />
      </section>
    )
}

export default QuizInfo