import React, {useEffect, useState} from 'react';
import { fetchData } from 'helpers';
// import { HTMLDecode } from 'assets/js/utils';

const Announcement = (props) => {
    const [noticeDetail,setNoticeDetail] = useState({});
    let param = props.location.search;
    const id = new URLSearchParams(param).get('noticeId');
    useEffect(() => {
      fetchData('getNoticeDetail', { id, num: 200 }).then((result) => {
        setNoticeDetail(result.data)
      }).catch((err) => {
        
      });
    },[id])
    return (
      <section>
         <style>
           {`
            .announcement-content {
             display: ${noticeDetail.content ? 'block' : 'none'};
            }
            .announcement-content img {
              width: 400px;
              height: 300px;
              margin:0 auto;
            }
           `}
         </style>
         <style jsx>{`
             .container {
               padding: 40px 24px 20px;
               box-sizing: border-box;
               border: 1px solid #d9d9d9;
               height: 521px;
               background: white;
               overflow-y: auto;
             }
             .title {
              //  width: 100%;
               height: 41px;
               line-height: 41px;
               text-align: center;
               font-weight: 600;
             }
             .time {
               text-align: center;
               margin-top: 10px;
               margin-bottom: 10px;
             }
             .content .text{
               line-height: 22px;
               margin-bottom: 18px;
             }
             .content .start {
               line-height: 40px;
             }
             .image {
               height: 200px;
               background: #d8d8d8;
             }
             .footer {
               margin-top: 50px;
               text-align: right;
             }
             .tip2 {
               margin-top: 6px;
             }
         `}
         </style>
         <section className="container">
            <h2 className="title">{noticeDetail.title}</h2>
            <p className="time">{noticeDetail.start_time}</p>
            {/* <div className="announcement-content" dangerouslySetInnerHTML={{__html: HTMLDecode(noticeDetail.content)}} /> */}
            <div className="announcement-content" dangerouslySetInnerHTML={{__html: noticeDetail.content}}></div>
            {/* <div className="footer">
              <p className="tip1">360游戏客服中心</p>
              <p className="tip2">{noticeDetail.start_time}</p>
            </div> */}
         </section>
      </section>
    )
}
                          
export default Announcement