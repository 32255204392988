import React from 'react';
import BaseIcon from 'assets/svgIcon/baseIcon';

// const content = (title, content) => {
//     return (
//         <section>
//             <style>
//                 {`
//                 .safety-tip {
//                     height: 165px;
//                     width: 165px;
//                     background: #FFEEE3;
//                     border: 1px dashed #333;
//                     box-sizing: border-box;
//                     // margin-left: 348px; 
//                     // margin-top: 38px;       
//                     padding: 30px 5px 0;
//                   }
//                   .safety-tip h6 {
//                    text-align: center;
//                    font-weight: 600;
//                    margin-bottom: 20px;
//                   }
//                   .tip-content {
//                    text-align: justify;
//                    font-size: 12px;
//                   }
//                   .ant-popover-inner-content {
//                       padding: 0;
//                   }
//                 `}
//             </style>
//             <div className="safety-tip">
//                 <h6>{title}</h6>
//                 <p className="tip-content">
//                     {content}
//                 </p>
//             </div>
//         </section>
//     )
// }

const VipService = (props) => {
    const { iconName, service } = props;
    const style = {
        width: '220px',
        height: '220px',
        border: '1px solid #DBD3C0',
        boxSizing: 'border-box',
        backgroundColor: 'white',
    }
    return (
      <section style={style}>
         <style jsx>{`
             .logo {
                 width: 74px;
                 height: 74px;
                 margin: 50px auto 0;
             }
             .service-content {
                color: #B18952;
                font-size: 16px;
                text-align: center;
                margin-top: 12px;
             }
         `}
         </style>
        <div className="logo"><BaseIcon iconName={iconName} width={74} height={74} /></div>
        <p className="service-content">{service}</p>
      </section>
    )
}


export default VipService