const data = [
    {
        question: '积分商城实物兑换及虚拟兑换发货时间',
        answer: `实物兑换商品在形成兑换订单后二十个工作日内，进行发货（周六日、国家法定假期不包含在工作日内）；虚拟兑换游戏内商品原则是实时收到，但由于各游戏系统问题，可能有延迟，请您耐心等待。如您在等待过程出有其他问题，可在客服中心首页最下方【活动问题】栏中，对应的表单并点击提交，我们接收后，会第一时间为您处理。`
    },
    {
        question: '如何找回账号',
        answer: `您可以通过以下方式找回账号：
1. 如果您的账号绑定了登录邮箱或密保手机时，您可以直接通过登录邮箱或密保手机使用账号密码进行登录
2. 如果您的账号绑定了密保邮箱时，您可以通过联系在线客服查询登录账号
3. 该账号未绑定密保手机/密保邮箱/登录邮箱：
①账号有充值，您可以联系在线客服，提供充值订单号查询登录账号
②账号无充值，您可以联系在线客服，提供游戏内的角色信息尝试查询（但由于每款游戏查询条件不同，这种方法只适合于部分游戏）`
    },
    {
        question: '如何关闭手游悬浮窗',
        answer: `关闭方法：仅限新版悬浮窗可以进行设置的。打开【悬浮窗】——【个人】——【头像下面第三个选项隐藏悬浮球】即可隐藏悬浮球了，目前重新登录后都需要再次操作进行隐藏。`,
    },
    {
        question: '如何设置/修改账号用户名',
        answer: `用户名一旦设置，无法修改
【注：360账号为360U开头的情况下，可以自行更改一次用户名】
设置方法如下：
1. 电脑登录 i.360.cn  登录自己的360账号后 — 点击页面右上角【未设置用户名】—进行设置用户名（用户名限制2-14个字符，支持中英文）
2. 手机登录 i.360.cn  登录自己的360账号后 — 点击【个人资料】—点击【用户名】进行设置—设置完毕后点击【保存】即可（安卓、苹果流程一致）`,
    },
    {
        question: '白金卡如何取消连续包月',
        answer: `如果您使用支付宝请参考如下操作：
 1.在支付宝中【设置】-【支付设置】-【免密支付/自动扣款】选择需要取消（解约）的项目
 2.在游戏内点击【浮窗】-【钱包】-【设置】，里面有管理支付宝免密支付。`,
    },
    {
        question: '如何获得手游礼包',
        answer: `查询礼包方法如下：
1.打开浏览器-地址栏输入http://ka.u.360.cn/进入福利360游戏大厅-在搜索栏输入您所玩的游戏-查找您想要的礼包即可
2.您可以登录http://u.360.cn/然后搜索您要玩的游戏，然后点击礼包，查找您需要的礼包 
3.点击游戏内【悬浮窗】-【礼包】可选择VIP等级礼包
4.点击【360游戏大厅】-【我】-【有奖任务】可进行查看是否有对应游戏礼包`,
    },
    {
        question: '历史充值扣款记录查询',
        answer: `历史充值扣款记录查询如下：
【电脑端支付宝查询方式】：请登录支付宝——交易记录——找到您对应月份游戏相应的充值记录；
【手机支付宝查看方式】：打开手机支付宝，【我的】——【账单】查看您对应月份的充值记录；
【微信充值查询方式】：请登录微信——【我】——【支付】——【钱包】——钱包右上角【账单】——查询交易记录找到指定日期充值记录——点击此笔充值记录后截图；
【网银充值查询方法】：请您复制链接：http://urlqh.cn/kawy8，到浏览器查看对应银行查询方式。`,
    },
    {
        question: '手游无法登录常规操作',
        answer: `手游无法登录一般为服务器或设备问题，可参考情况如下：
一、服务器维护/开合服等官方操作行为
二、因故障导致的错误提示【排故障操作】：
建议您可以先对手机进行一下全面、彻底的清理。另外您可以登录360手游http://bbs.mgamer.cn/找到对应游戏版块，查看是否为游戏更新版本或例行维护导致。如为个别现象，请您尝试以下方法：
【注：如果您的游戏是单机游戏的话建议您不要卸载游戏，单机游戏重新下载，游戏数据是会消失的。】
1、清理缓存，关闭空闲软件，再次登录游戏；
2、使用360安全卫士清理加速，并检查手机运行内存（一般在手机设置里面查看）；
3、关闭手机重新启动；
4、确认网络是否正常，请查看手机其它应用或其它设备联网是否正常；
5、如果是网游，请卸载游戏，通过360游戏大厅或360手机助手重新下载安装。（360游戏大厅需要通过360手机助手下载，如您通过360手机助手下载游戏，您需要在下载时确定好下载渠道）
【注：如果上述方式无效，建议您打开客服中心（https://gm.game.360.cn/），在主页的最下方点击【游戏问题】栏中的【无法登录】按钮（如没有显示请点击栏中的【更多】按钮），并填写您的游戏信息后点击发起咨询，填写表单并点击提交表单，工作人员会尽快为您处理请您耐心等待。】`,
    },
    {
        question: '页游无法登录常规操作',
        answer: `一般会有两种可能：
一、服务器维护/开合服等官方操作行为
如果是第一种的情况下，还请您耐心等待维护结束。
二、故障导致的错误提示【排故障操作】如下：
建议您可以先进行一下全面、彻底的清理：
1、换一个登录方式,清理缓存，优化网络,优化电脑上的DNS,更新浏览器尝试一下。（可通过360安全卫士中的DNS修复功能进行操作）
2、如果您还是进不去游戏，您可以尝试切换浏览器极速模式、兼容模式或下载360极速浏览器进行尝试的。
3、如果是微端或者游戏大厅进入，建议您卸载重新安装一下
【注：如果上述方式无效，建议您打开客服中心（https://gm.game.360.cn/），点击在主页的最下方【游戏问题】栏中的【无法登录】按钮（如没有显示请点击栏中的【更多】按钮），并填写您的游戏信息后点击发起咨询，填写表单并点击提交表单，工作人员会尽快为您处理请您耐心等待。】`,
    },
    {
        question: '手机游戏如何切换账号',
        answer: `已登录状态您可以尝试以下方法：
1、可以点击游戏里360手机助手的悬浮窗-个人-切换账号。
2、部分游戏在加载登录时界面上方一般有一个白色的“切换账号”提示，您点击后可以尝试切换账号。
未登录状态您可以尝试以下方法：
登录游戏时账号登录界面左下方会提示切换登录方式（账号密码登录及手机号短信验证登录），您可以选择您常用的登录方式进行操作。`,
    },
    {
        question: '积分/云钻竞拍规则',
        answer: `竞拍规则如下：
1.竞拍结束后根据商品数量来确定竞拍成功的用户，例如商品数量为10，前10名出价的用户可中标
2.出价相同的用户，先出价成功的用户排名靠前
3.出价成功后积分将会冻结
4.竞拍中,出价用户被淘汰,可取消竞拍，取消后积分返回该用户360积分商城账户
5.竞拍结束后，未竞拍成功的用户积分会返回该用户360积分商城账户
6.竞拍结束后,竞拍成功的用户积分将扣除,无论是否领取奖品,积分均不退回。
7.如果是需要支付金额的商品,竟拍成功后无论是否支付购买,所有消耗的积分不退回
8.中标后请在5天内支付,否则视为放弃购买资格。
9.积分相关竞拍：缴纳的门票费不会退回。`,
    },
    {
        question: '二级密码修改',
        answer: `二级密码是游戏中的财产保护密码，包括仓库密码、背包密码等。
需要您回到首页，在客服中心首页最下方【游戏问题】栏中，选择【二级密码】（如没有显示请点击栏中的【更多】）的表单并点击提交，我们接收到后，会第一时间为您处理
以下特殊游戏处理方案：
1.《问道》需要您在游戏内点击“设置”-“管理”-“安全锁”，申请强制解锁，耐心等待7天，自动清除。
2.《大话西游》需要您在游戏内点击“系统”申请强制解锁，耐心等待5天，自动清除。
3.《梦幻西游》需要玩家您提供充值常用设备MAC码（充值最多的设备）+角色ID，填写首页最下方【游戏问题】栏中，选择【二级密码】（如没有显示请点击栏中的【更多】）的表单并点击提交；或者您在游戏内点击“系统”申请强制解锁，耐心等待10天，自动清除。
4.端游《桃花源记2》在首页最下方【游戏问题】栏中，选择【二级密码】（如没有显示请点击栏中的【更多】）的表单并点击提交；另需要您提供游戏内认证的身份证反正面照片`,
    },
    {
        question: '修改账号昵称',
        answer: `昵称不限制修改次数
设置方法如下：
1. 电脑登录 i.360.cn  登录自己的360账号后 — 点击头像右侧【设置昵称】进行设置（昵称限制2-14个字符，支持中英文）
2. 手机登录 i.360.cn  登录自己的360账号后 — 点击【个人资料】—点击【昵称】进行设置—设置完毕后点击【保存】即可（安卓、苹果流程一致）"`,
    },
    {
        question: '如何注销账号',
        answer: `浏览器登录i.360.cn，登录360账号：
①点击【账号安全】—点击【账号服务】—点击【继续注销账号】
②选择验证方式—输入收到的验证码—账号注销确认（等待时间约20秒左右）
③点击【继续注销】—勾选“已阅读并同意《360账号注销协议》”—点击【同意并继续】
④勾选离开原因—填写联系邮箱，输入邮箱收到的验证码—页面提示申请提交成功
⑤结果会在4个工作日内发送到用户的联系邮箱（如果用户账号下未绑定密保信息，则点击【继续注销账号】后需要验证账号登录密码）`,
    },
    {
        question: '如何设置实名认证',
        answer: `浏览器登录wan.360.cn：登录自己的360账号后，点击【个人中心】—账号安全—防沉迷设置【立即设置】—填写姓名、身份证号后点击【确定】即可保存成功；
游戏内：登录自己的360账号后，游戏页面会弹窗提示填写实名认证，填写姓名、身份证号后点击【确定】即可保存成功；`,
    },
    {
        question: '怎样冻结/解冻账号',
        answer: `1. 账号下有绑定且激活的密保手机，并与安全卫士进行关联时：
账号卫士APP登录自己的360账号，— 点击【紧急冻结】— 点击【冻结账号】即可冻结成功；解冻账号点击【解冻账号】即可解冻；
2. 账号下绑定密保手机时，可发短信冻结/解冻账号：
①冻结：使用绑定手机号发送冻结指令 SDZH 至 10690133605 冻结帐号，冻结后的帐号无法登录  
②解冻：您可以使用绑定手机号发送解冻指令 JSZH 至 10690133605 解除冻结`,
    },
    {
        question: '360币支付密码设置',
        answer: `pay.360.cn：浏览器登录pay.360.cn，登录自己的360账号后，页面会弹窗提示，输入登录密码— 设置支付密码— 确认支付密码 — 设置安全问题 — 输入安全问题答案— 输入账号下绑定的密保手机收到的验证码— 点击【确定】即可设置成功
        
注：如账号下没有绑定密保手机则无法设置360币支付密码及安全问题）`,
    },
    {
        question: '如何修改360币支付的安全问题',
        answer: `您可以点击进入pay.360.cn，登录自己的360账号后，点击【我的账户】--【安全设置】—在安全设置中找到【安全问题】右侧点击【修改】即可进行安全问题的修改。`,
    },
    {
        question: '360币重置支付密码',
        answer: `以下情况仅适用于安全问题知道答案的情况下用户自行操作
pay.360.cn：浏览器登录pay.360.cn，登录自己的360账号后，点击【我的账户】— 点击【修改支付密码】— 点击【忘记密码】— 输入设置的安全问题答案— 设置新密码— 确认新密码— 点击【确定】即可保存成功；

注：如果用户将安全问题答案一并忘记，需联系客服处理，手游联系邮箱为：m-kefu@360.cn   页游邮箱为：payhelp@360.cn`,
    },
    {
        question: '换绑360密保手机号',
        answer: `1. 浏览器登录i.360.cn进行设置：
官网PC版：
①登录360账号后—点击左侧常用操作中绑定手机【修改】——进行设置
②登录360账号后—点击账号安全—密保工具—绑定手机【修改】——进行设置
③登录360账号后—点击账号安全—点击左侧绑定手机——【修改】——进行设置；
官网手机版：
登录360账号后—点击【我的密保】—绑定手机【修改】——进行设置（安卓、苹果流程一致）
2. 浏览器登录wan.360.cn进行设置
登录360账号后—点击【个人中心】—点击【账号安全】—绑定手机【修改】——进行设置（安卓、苹果与网页版流程一致）
3. 手游游戏内：
登录360账号—点击悬浮球—点击【安全中心】—点击【密保工具】—绑定手机【修改】——进行设置
特殊说明：
①账号已绑定登录邮箱/密保邮箱（密保信息可用且激活状态）情况下自行操作修改密保手机
②密保信息不可用情况下或手机换号无法自助修改绑定手机时需填写表单提交账号申诉，由工作人员核实信息（如账号是360U开头且账号密保信息只绑定了手机号，无法直接删除账号密保手机，需要修改完账号用户名后才可以进行操作）`,
    },
    {
        question: '换绑360密保邮箱',
        answer: `1. 浏览器登录i.360.cn进行更改：
登录360账号后—点击账号安全—密保工具—密保邮箱【修改】—进行更换（激活完成后即绑定成功）
2. 浏览器登录wan.360.cn进行更改：
登录360账号后—点击【个人中心】—点击【账号安全】—密保邮箱【修改】—进行更换（激活完成后即绑定成功）
特殊说明：
账号如有绑定其他密保信息（密保信息可用且激活状态），在更换密保邮箱时，需先验证其他密保信息`,
    },
    {
        question: '换绑360登录邮箱',
        answer: `1. 浏览器登录i.360.cn进行更改：
官网PC版：
①登录360账号后—点击左侧常用操作中登录邮箱【修改】—进行更换（激活完成后即绑定成功）
②登录360账号后—点击账号安全—密保工具—登录邮箱【修改】—进行更换（激活完成后即绑定成功）
官网手机版：
登录360账号后—点击【我的密保】—登录邮箱【修改】—进行更换（激活完成后即绑定成功）（安卓、苹果流程一致）
2.手游游戏内：进入游戏后登录自己的360账号—点击悬浮球—点击【安全中心】—点击【密保工具】—登录邮箱【修改】—进行更换（激活完成后即绑定成功）
特殊说明：
账号如有绑定其他密保信息（密保信息可用且激活状态），在修改登录邮箱时，需先验证其他密保信息`,
    },
]

export default data;