
export default {
    login: [
        {
            question: '手机游戏无法登录',
            answer: `手游无法登录一般为服务器或设备问题，可参考情况如下：
一、服务器维护/开合服等官方操作行为
二、因故障导致的错误提示【排故障操作】：
建议您可以先对手机进行一下全面、彻底的清理。另外您可以登录360手游http://bbs.mgamer.cn/找到对应游戏版块，查看是否为游戏更新版本或例行维护导致。如为个别现象，请您尝试以下方法：
【注：如果您的游戏是单机游戏的话建议您不要卸载游戏，单机游戏重新下载，游戏数据是会消失的。】
1、清理缓存，关闭空闲软件，再次登录游戏
2、使用360安全卫士清理加速，并检查手机运行内存（一般在手机设置里面查看）
3、关闭手机重新启动
4、确认网络是否正常，请查看手机其它应用或其它设备联网是否正常
5、如果是网游，请卸载游戏，通过360游戏大厅或360手机助手重新下载安装。360游戏大厅需要通过360手机助手下载，如您通过360手机助手下载游戏，您需要在下载时确定好下载渠道。
【注：如果上述方式无效，建议您打开客服中心（https://gm.game.360.cn/），在主页的最下方点击【游戏问题】栏中的【无法登录】按钮（如没有显示请点击栏中的【更多】按钮），并填写您的游戏信息后点击发起咨询，填写表单并点击提交表单，工作人员会尽快为您处理请您耐心等待。】`,
        },
        {
            question: '手机游戏卡顿、黑屏、闪退怎么办',
            answer: `建议您可以先对手机进行一下全面、彻底的清理：
【注：如果您的游戏是单机游戏的话建议您不要卸载游戏，单机游戏重新下载，游戏数据是会消失的。】
1、清理缓存，关闭空闲软件，再次登录游戏
2、使用360安全卫士清理加速，并检查手机运行内存（一般在手机设置里面查看）
3、关闭手机重新启动
4、确认网络是否正常，请查看手机其它应用或其它设备联网是否正常
5、如果是网游，请卸载游戏，通过360游戏大厅或360手机助手重新下载安装。360游戏大厅需要通过360手机助手下载，如您通过360手机助手下载游戏，您需要在下载时确定好下载渠道。
【注：如果上述方式无效，建议您打开客服中心（https://gm.game.360.cn/），点击在主页的最下方【游戏问题】栏中的【闪退、卡顿、掉线】按钮（如没有显示请点击栏中的【更多】按钮），并填写您的游戏信息后点击发起咨询，填写表单并点击提交表单，工作人员会尽快为您处理请您耐心等待。】`,
        },
        {
            question: '手机游戏如何切换账号',
            answer: `已登录状态您可以尝试以下方法：
1、可以点击游戏里360手机助手的悬浮窗-个人-切换账号。
2、部分游戏在加载登录时界面上方一般有一个白色的“切换账号”提示，您点击后可以尝试切换账号。
未登录状态您可以尝试以下方法：
登录游戏时账号登录界面左下方会提示切换登录方式（账号密码登录及手机号短信验证登录），您可以选择您常用的登录方式进行操作。`,
        },
        {
            question: '手机游戏下载完之后，以前的角色数据丢失了？',
            answer: `角色数据消失有四种情况：
1、更新后导致平台出现错误，因为不同平台的游戏数据是不互通的，建议您登录360游戏大厅重新下载登录。
2、单机游戏重新下载，游戏数据是会消失的（部分游戏可以进行数据上传，建议您及时备份）。
3、游戏数据异常，建议联系游戏客服进行处理。
4、账号/区服登录错误，建议您仔细核对
【注：游戏数据异常导致角色丢失问题，建议您打开客服中心（https://gm.game.360.cn/），点击在主页的最下方【游戏问题】栏中的【角色查询】按钮（如没有显示请点击栏中的【更多】按钮），并填写您的游戏信息后点击发起咨询，填写表单并点击提交表单，工作人员会尽快为您处理请您耐心等待。】`,
        },
        {
            question: '如何下载360平台的游戏',
            answer: `手游：手机可以下载360游戏大厅或360手机助手，或者进入手游官网u.360.cn进行下载。（360游戏大厅需要通过360手机助手下载，如您通过360手机助手下载游戏，您需要在下载时确定好下载渠道）
页游：登录wan.360.cn，进行下载微端/大厅
端游：登录youxi.com，点击【全部游戏】后在搜索栏中输入游戏名，搜索对应游戏后点击进入官网下载游戏即可。`,
        },
        {
            question: '网页游戏无法登录游戏',
            answer: `一般会有两种可能：
一、服务器维护/开合服等官方操作行为
如果是第一种的情况下，还请您耐心等待维护结束。
二、故障导致的错误提示【排故障操作】如下：
建议您可以先进行一下全面、彻底的清理：
1、换一个登录方式,清理缓存,优化网络,优化电脑上的DNS,更新浏览器尝试一下。（可通过360安全卫士中的DNS修复功能进行操作）
2、如果您还是进不去游戏,您可以尝试切换极速模式、兼容模式或者下载360急速浏览器进行尝试的。
3、如果是微端或者游戏大厅进入，建议您卸载重新安装一下
【注：如果上述方式无效，建议您打开客服中心（https://gm.game.360.cn/），点击在主页的最下方【游戏问题】栏中的【无法登录】按钮（如没有显示请点击栏中的【更多】按钮），并填写您的游戏信息后点击发起咨询，填写表单并点击提交表单，工作人员会尽快为您处理请您耐心等待。】`,
        },
        {
            question: '网页游戏如何切换账号？',
            answer: `切换账号三种方法如下：
1、如果您使用的是游戏微端的话（如《神座》），点击您账号边的【注销】按钮后，输入您想要切换的账号即可。
2、如果您使用的是360游戏大厅登录的游戏微端的话，点击您的头像【切换账号】--【用其他账户登录】输入您想要切换的账号即可。
3、如果您使用的是网页登录游戏的话，您可以在wan.360.cn中，点击您头像右方的退出按钮，退出账号后输入您想要切换的账号即可。`,
        },
        {
            question: '网页游戏游戏卡顿、黑屏、闪退怎么办？',
            answer: `您好，建议您可以先进行一下全面、彻底的清理：
1、先关闭所有网页，在您电脑左下角“开始”—“运行”—输入cmd—确认在出来的窗口里输入del *.sol /s（注意两个l后面都有空格的）然后按回车，运行完后再打开游戏试试哦。
2、换一个登录方式，清理缓存，优化网络，优化电脑上的DNS，更新浏览器尝试一下。（可通过360安全卫士中的DNS修复功能进行操作）
3、如果您还是进不去游戏，您可以尝试切换浏览器极速模式、兼容模式或下载360极速浏览器进行尝试的。
4、如果您是微端或者游戏大厅进入，建议您卸载重新安装一下。
【注：如果上述方式无效，建议您打开客服中心（https://gm.game.360.cn/），点击在主页的最下方【游戏问题】栏中的【闪退、卡顿、掉线】按钮（如没有显示请点击栏中的【更多】按钮），并填写您的游戏信息后点击发起咨询，填写表单并点击提交表单，工作人员将尽快为您处理请您耐心等待。】`,
        },
    ],
    game: [
        {
            question: 'COC类游戏（海岛奇兵、部落冲突、皇室战争）游戏问题如何处理？',
            answer: `由于coc系列（海岛奇兵、部落冲突、皇室战争）游戏由官方统一管理，所有游戏内问题要您通过【游戏设置】-【帮助与支持】提交问题申诉反馈。
操作步骤如下：
1.首先点击部落主界面的“齿轮”图标
2.在设置界面找到“帮助与支持”
3.点进去，然后选择任意一项
4.再点击右下方的“否”
5.这时候会出现一个“联系我们”的选项
6.点击之后就可以给官方客服发送邮件了`,
        },
        {
            question: '游戏几率太小怎么提高？',
            answer: `机率是由系统判定的，并非人为操控，每个玩家的机率都是一样的，希望不要影响您的游戏心情。`,
        },
        {
            question: '为什么战力高的打不过战力低的',
            answer: `在游戏中影响伤害的因素是多方面的，同样也会受到职业、互相角色属性克制、技能释放顺序等因素影响，建议您可以在游戏中与其他玩家多多探讨交流。`,
        },
        {
            question: '可以将游戏数据转到其他平台吗',
            answer: `非常抱歉，目前暂时无法进行游戏数据平台转移。`,
        },
        {
            question: '二级密码修改',
            answer: `二级密码是游戏中的财产保护密码，包括仓库密码、背包密码等。
需要您回到首页，在客服中心首页最下方【游戏问题】栏中，选择【二级密码】（如没有显示请点击栏中的【更多】）的表单并点击提交，我们接收到后，会第一时间为您处理
以下特殊游戏处理方案：
1.手游《问道》需要您在游戏内点击“设置”-“管理”-“安全锁”，申请强制解锁，耐心等待7天，自动清除。
2.手游《大话西游》需要您在游戏内点击“系统”申请强制解锁，耐心等待5天，自动清除。
3.手游《梦幻西游》需要玩家您提供充值常用设备MAC码（充值最多的设备）+角色ID，填写首页最下方【游戏问题】栏中，选择【二级密码】（如没有显示请点击栏中的【更多】）的表单并点击提交或者您在游戏内点击“系统”申请强制解锁，耐心等待10天，自动清除。
4.端游《桃花源记2》在首页最下方【游戏问题】栏中，选择【二级密码】（如没有显示请点击栏中的【更多】）的表单并点击提交另需要您提供游戏内认证的身份证反正面照片`,
        },
        {
            question: '微端如何下载',
            answer: `1.您可以登录wan.360.cn这个网址后，在右上角搜索您想要下载的游戏名称，然后点击游戏官网首页进行微端的下载。
2.您可以登录wan.360.cn这个网址后，点击您头像右方的微端有礼即可进行查找下载。`,
        },
        {
            question: '角色封禁/禁言如何处理',
            answer: `您角色封禁/禁言一般是系统检测到您在游戏有违反游戏规则的行为（例如：游戏内刷屏、言论不当、第三方软件、发布不良信息等行为），如果您没有过违规行为，您可以通过联系客服的方式进行解封/解禁操作。在客服中心首页最下方【游戏问题】栏中，选择【申请解除封禁/禁言】（如没有显示请点击栏中的【更多】）的表单并点击提交，工作人员接收到后会第一时间为您处理。`,
        },
        {
            question: '无线网卡顿，如何解决？',
            answer: `对于您出现的情况原因可能如下：
1.有人蹭网，关闭无线网络或者下载（路由器卫士等）网络管理软件，对陌生设备进行屏蔽。
2.电脑的系统垃圾过多，造成网络卡的假象，建议使用360安全卫士或清理工具/软件进行优化。
3.路由器或Modem开的时间长了，因为数据冗余量增加，可能会造成处理速度慢，拔掉电源，重新插上电源即可。 
4.路由器或Modem过热，导致其数据处理慢，将路由器或Modem换到通风良好的地方即可。 
5.运营商问题：晚上或者人群使用高峰期，诸如学校，出现网络较大延迟，直接拨打宽带商的客服或者当初办理的维修人员的电话即可。 
6.宽带商的缓存服务器有缓存，打电话给宽带商的客服或者当初办理的维修人员，请他们清理服务器里的缓存。`,
        },
        {
            question: '游戏更新失败',
            answer: `以下是PC端和手机游戏通用的解决方式：
1.请检查手机/电脑网络是否正常，是否将360游戏大厅联网权限设置为黑名单，或者暂时关闭安全软件
2.检查是否有游戏残留进程，建议您关闭残留进程后再尝试安装更新
3.检查是否跨多版本更新，建议您直接打开游戏更新或卸载后重新安装游戏
4.检查游戏安装目录是否被修改，请将游戏从列表移除后重新扫描添加并更新
5.检查360游戏大厅设置是否正确，恢复默认
6.更新过程中请不要暂停，关闭其他无关进程，否则容易造成更新包损坏无法安装`,
        },
    ],
    recharge: [
        {
            question: '游戏内充值未到账怎么办？',
            answer: `由于网络波动等原因，充值可能会有15-30min的充值延迟，请您耐心等待。
如果充值超过30分钟请您回到首页，在首页最下方【充值问题】栏中，选择【充值未到账】（如没有显示请点击栏中的【更多】）的表单并点击提交，工作人员接收到后会第一时间为您处理。`,
        },
        {
            question: '如何查询手机游戏内的充值记录',
            answer: `两种方法可以查询手游充值记录：
1.新版本查询方式：打开游戏，点击游戏内【悬浮窗】——【钱包】——【其他】——【交易记录】进行查询。 
2.老版本查询方式：打开游戏，点击游戏内【悬浮窗】——【福利】——【360钱包】——【左上角的3条小横线】——【交易记录】（适用于非单机游戏、非苹果版本游戏）。
备注：页游端游无法在游戏内进行查询，只可以根据自己的（支付宝、微信、银行卡）付款记录查询。`,
        },
        {
            question: '有哪些充值方式',
            answer: `充值方式如下：
手游：
1.支付宝充值
2.微信充值
3.网银支付（建设银行、工商银行、农业银行、招商银行、中国银行、邮政银行、交通银行、平安银行、兴业银行等）
4.360币
页游&端游：
1.扫码支付（微信、支付宝）
2.网银支付（建设银行、工商银行、农业银行、招商银行、中国银行、邮政银行、交通银行、平安银行、兴业银行等）
3.点卡支付
4.360币支付`,
        },
        {
            question: '历史充值扣款记录查询',
            answer: `历史充值扣款记录查询如下：
【电脑端支付宝查询方式】：请登录支付宝——交易记录——找到您对应月份游戏相应的充值记录
【手机支付宝查看方式】：打开手机支付宝，【我的】——【账单】查看您对应月份的充值记录
【微信充值查询方式】：请登录微信——【我】——【支付】——【钱包】——钱包右上角【账单】——查询交易记录找到指定日期充值记录——点击此笔充值记录后截图
【网银充值查询方法】：请您复制链接：http://urlqh.cn/kawy8，到浏览器查看对应银行查询方式。（此链接仅包含部分银行的查询功能，其他银行查询请登录银行官网咨询客服或自助查询）`,
        },
        {
            question: '支付宝/微信第三方扣款截图删除如何再次获取',
            answer: `【支付宝删记录查询方法】
1.如果您的支付宝记录已经删除，请您使用电脑登录支付宝-点击交易记录-右上角回收站-找到对应的支出详情-点击详情后截图（截图需包含完整的交易号、时间、金额）然后将截图添加附件发送给我们以便我们核实处理。
2.如果您的支付宝支出记录回收站被清空，您可以联系支付宝在线客服（支付宝-我的客服-交易记录-如何查看交易记录-向我提问）告知客服查询已删除回收站的支出记录查询，告知客服具体交易时间，然后索要19位数的订单号，提供给我们。
【微信删除记录查询方法】
微信充值记录已删的情况下，您可以联系微信客服申请找回已删除的充值记录。（打开微信搜索【微信支付】公众号，点击【其他帮助】—【更多问题】—【联系客服】打开微信【我】—点击【支付】—【钱包】—点击【帮助中心】—【联系客服】）以上联系微信客服找回充值记录方式仅供参考。`,
        },
    ],
    platform: [
        {
            question: '360平台充值有折扣吗',
            answer: `平台会不定期开展平台活动，建议您多多留意我们的活动信息`,
        },
        {
            question: '白金卡取消连续包月方法',
            answer: `如果您使用支付宝请参考如下操作：
1.在支付宝中【设置】-【支付设置】-【免密支付/自动扣款】选择需要取消（解约）的项目
2.在游戏内点击【浮窗】-【钱包】-【设置】，里面有管理支付宝免密支付。`,
        },
        {
            question: '如何获得手游礼包',
            answer: `礼包查询方法如下：
1.开浏览器-地址栏输入http://ka.u.360.cn/进入福利360游戏大厅-在搜索栏输入您所玩的游戏-查找您想要的礼包即可
2.您还可以登录http://u.360.cn/然后搜索您要玩的游戏，然后点击礼包，然后查找您需要的礼包
3.点击游戏内【悬浮窗】-【礼包】可选择VIP等级礼包
4.点击【360游戏大厅】-【我】-【有奖任务】可进行查看是否有对应游戏礼包
手游礼包码如何使用：
进入游戏-找到礼包或设置图标点击打开-输入礼包码即可；或者在悬浮窗-福利-代金券-兑换码输入即可`,
        },
        {
            question: '积分商城实物兑换及虚拟兑换发货时间',
            answer: `您好，实物兑换商品在形成兑换订单后二十个工作日发货（周六日、国家法定假期不包含在工作日内）虚拟兑换商品原则是实时收到，但由于各游戏应用的不同或系统问题，可能有延迟，请您耐心等待。如您在等待过程出有其他问题，可在客服中心首页最下方【活动问题】栏中，对应的表单并点击提交，我们接收后，会第一时间为您处理。`,
        },
        {
            question: '如何关闭手游悬浮窗',
            answer: `关闭方法：
            仅限新版悬浮窗可以进行设置的打开【悬浮窗】——【个人】——【头像下面第三个选项隐藏悬浮球】即可隐藏悬浮球了，目前重新登录后都需要再次操作进行隐藏。`,
        },
        {
            question: '积分/云钻竞拍规则',
            answer: `竞拍规则如下：
1.竞拍结束后根据商品数量来确定竞拍成功的用户，例如商品数量为10，前10名出价的用户可中标
2.出价相同的用户，先出价成功的用户排名靠前
3.出价成功后积分/云钻将会冻结
4.竞拍中，出价用户被淘汰,可取消竞拍，取消后积分/云钻返回该用户360积分商城账户
5.竞拍结束后，未竞拍成功的用户积分/云钻会返回该用户360积分商城账户
6.竞拍结束后，竞拍成功的用户积分/云钻将被扣除,无论是否领取奖品,积分/云钻均不退回。
7.如果是需要支付金额的商品,竟拍成功后无论是否支付购买,所有消耗的积分/云钻均不退回
8.中标后请在活动时间内支付,否则视为放弃购买资格。
9.积分竞拍：缴纳的门票费不会退回。`,
        },
        {
            question: '忘记360币支付密码，如何重置',
            answer: `1. 浏览器登录pay.360.cn，登录自己的360账号后，点击【我的账户】— 点击【修改支付密码】— 点击【忘记密码】— 输入设置的安全问题答案— 设置新密码— 确认新密码— 点击【确定】即可保存成功
2. 如果您将安全问题答案一并忘记了，需要联系客服处理;`,
        },
        {
            question: '如何设置360币支付密码/安全问题',
            answer: `1.浏览器登录pay.360.cn，登录自己的360账号后，点击【我的账户】，输入登录密码— 设置支付密码— 确认支付密码 — 设置安全问题 — 输入安全问题答案— 输入账号下绑定的密保手机收到的验证码— 点击【确定】即可设置成功
2. 如果您将安全问题答案一并忘记，需联系工作人员处理`,
        },
        {
            question: '如何取消支付宝免密支付',
            answer: `操作如下：打开支付宝点击【我的】——【设置】——【支付设置】——【免密支付/自动扣款】取消您的设置即可`,
        },
        {
            question: '游戏大厅切换极速模式一直白屏、加载慢',
            answer: `1.CPU/内存占用满的情况下会有机率出现无法加载而导致一直白屏的情况，关闭游戏标签重新打开即可。
2.首次切换极速模式加载耗时相对普通打开界面的时间稍长，二次切换加载速度会有所提升`,
        },
        {
            question: '游戏大厅游戏卡加载、卡顿、黑屏、白屏或掉线',
            answer: `使用360浏览器并切换到极速模式，若正常则是缓存问题。 大厅切换兼容/极速模式，只能勾选一个。
可以分别尝试修复（大厅任务栏中“修复”）：
极速版：一键修复，勾选关闭后彻底清理，重启游戏大厅。 
兼容版：一键修复。 
若浏览器极速模式下出现同样的问题，则是网络问题。
1.可尝试优化DNS（可通过360安全卫士中的DNS修复功能进行操作）
2.您可以在客服中心首页最下方【游戏问题】栏中，点击【闪退、卡顿、掉线】（如没有显示请点击栏中的【更多】）的表单并点击提交
3.向相关网络运营商反馈。`,
        },
        {
            question: '游戏大厅多开后游戏不流畅，如何解决？',
            answer: `游戏大厅提供多开功能，您可根据电脑的状况选择多开账号，建议不要超过5个。`,
        },
        {
            question: '大厅高级工具不运行',
            answer: `1.请确认电脑的配置：适合机型（win7、XP、win10）等，分辨率1280*768以上
2.请您使用360卫士，进行工具检测，对异常进行一键修复
3.若您已修复，您可以尝试使用管理员权限启动大厅、或关闭左侧小号列表。
win7：桌面选中游戏大厅，右击鼠标—以管理员身份运行
win10：桌面选中游戏大厅，右击鼠标——属性—兼容性—勾选以管理员身份运行此程序，点击确定。
4.如果以上方式还是无法解决，联系在线客服人员索要万能修复工具，进行检测修复。`,
        },
        {
            question: '大厅下载、解压失败',
            answer: `1.网络问题，建议您检查一下网络是否正常，或者切换一下网络，尝试重新下载。
2.杀毒软件拦截，您可以关闭杀毒软件或取消拦截，保证可下载加载文件。`,
        },
    ],
    account: [
        {
            question: '如何找回帐号',
            answer: `您可以通过以下方式找回账号：
1. 如果您的账号绑定了登录邮箱或密保手机时，您可以直接通过登录邮箱或密保手机作为帐号进行登录
2. 如果您的账号绑定了密保邮箱时，您可以通过联系在线客服查询登录账号
3. 该账号未绑定密保手机/密保邮箱/登录邮箱：
①账号有充值，您可以联系在线客服，提供充值订单号查询登录账号
②账号无充值，您可以联系在线客服，提供游戏内的角色信息尝试查询（但由于每款游戏查询条件不同，这种方法只适合于部分游戏）`,
        },
        {
            question: '如何绑定密保手机',
            answer: `1、浏览器登录i.360.cn进行设置：
官网PC版：
①登录360账号后—点击左侧常用操作中绑定手机【设置】——进行设置
②登录360账号后—点击账号安全—密保工具—绑定手机【立即设置】——进行设置
③登录360账号后—点击账号安全—点击绑定手机——【现在绑定】——进行设置
④登录360账号后—点击【立即设置密保工具】—密保工具—绑定手机【立即设置】——进行设置
（账号如绑定其他密保信息时，请以①②③进行操作）
官网手机版：
登录360账号后—点击【我的密保】—绑定手机【立即设置】—输入需要绑定的手机号—获取验证码，输入验证码—点击【绑定手机】即可绑定成功（安卓、苹果流程一致）
2、浏览器登录wan.360.cn进行设置
登录360账号后—点击【个人中心】—点击【账号安全】—绑定手机【立即设置】——进行设置（安卓、苹果与网页版流程一致）
3、手游游戏内：点击悬浮球—【安全中心】—【密保工具】—绑定手机【立即设置】—进行设置
特殊说明：
账号如有绑定其他密保信息（密保信息可用且激活状态），在设置绑定密保手机时，需先验证其他密保信息无误后，可继续绑定密保手机`,
        },
        {
            question: '如何解绑密保手机',
            answer: `浏览器登录i.360.cn进行解绑：
①登录360账号后—点击左侧常用操作中绑定手机【解绑】——进行解绑
②登录360账号后—点击账号安全—密保工具—绑定手机【解绑】——进行解绑
③登录360账号后—点击账号安全—点击左侧绑定手机——【解绑】——进行解绑
特殊说明：
账号已绑定登录邮箱/密保邮箱（密保信息可用且激活状态）时，可自行操作解绑密保手机`,
        },
        {
            question: '如何更换密保手机',
            answer: `1、浏览器登录i.360.cn进行设置：
官网PC版：
①登录360账号后—点击左侧常用操作中绑定手机【修改】——进行设置
②登录360账号后—点击账号安全—密保工具—绑定手机【修改】——进行设置
③登录360账号后—点击账号安全—点击左侧绑定手机——【修改】——进行设置
官网手机版：
登录360账号后—点击【我的密保】—绑定手机【修改】——进行设置（安卓、苹果流程一致）
2、浏览器登录wan.360.cn进行设置
登录360账号后—点击【个人中心】—点击【账号安全】—绑定手机【修改】——进行设置（安卓、苹果与网页版流程一致）
3、手游游戏内：登录360账号—点击悬浮球—点击【安全中心】—点击【密保工具】—绑定手机【修改】——进行设置
特殊说明：
①账号已绑定登录邮箱/密保邮箱（密保信息可用且激活状态）情况下可自行操作修改密保手机
②密保信息不可用情况下或手机换号无法自助修改绑定手机时需填写表单提交账号申诉，由工作人员核实信息（如账号是360U开头且账号密保信息只绑定了手机号，无法直接删除账号密保手机，需要修改完账号用户名后才可以进行操作）`,
        },
        {
            question: '手机换号，无法自助修改绑定手机怎么办',
            answer: `您可以登录客服中心（https://gm.game.360.cn/），在主页的最下方点击【账号问题】栏中的【清空密保手机】按钮，填写表单相关信息并点击提交表单，工作人员会尽快为您处理，请您耐心等待。`,
        },
        {
            question: '如何绑定密保邮箱',
            answer: `1、浏览器登录i.360.cn进行设置：
登录360账号后—点击账号安全—左侧【登录邮箱】—点击邮箱常见问题中【我有一个邮箱已经注册了其他帐号，怎样能通过邮箱找回本帐号的密码？】—在页面出现的文字答复中点击【设置为当前帐号的密保邮箱】———进行绑定（激活完成后即绑定成功）
2、浏览器登录wan.360.cn进行设置：
登录360账号后—点击【个人中心】—点击【账号安全】—密保邮箱【立即设置】——进行绑定（激活完成后即绑定成功）
特殊说明：
账号如有绑定其他密保信息（密保信息可用且激活状态），在更换密保邮箱时，需先验证其他密保信息`,
        },
        {
            question: '如何更换密保邮箱',
            answer: `1、浏览器登录i.360.cn进行更改：
登录360账号后—点击账号安全—密保工具—密保邮箱【修改】—进行更换（激活完成后即绑定成功）
2、浏览器登录wan.360.cn进行更改：
登录360账号后—点击【个人中心】—点击【账号安全】—密保邮箱【修改】—进行更换（激活完成后即绑定成功）
特殊说明：
账号如有绑定其他密保信息（密保信息可用且激活状态），在更换密保邮箱时，需先验证其他密保信息`,
        },
        {
            question: '如何绑定登录邮箱',
            answer: `1. 浏览器登录i.360.cn进行更改：
①登录360账号后—点击左侧常用操作中登录邮箱【设置】———进行绑定（激活完成后即绑定成功）
②登录360账号后—点击账号安全—密保工具—登录邮箱【立即设置】———进行绑定（激活完成后即绑定成功）
③登录360账号后—点击账号安全—点击左侧登录邮箱——登录邮箱设置
2. 官网手机版：登录360账号后—点击【我的密保】—登录邮箱【立即设置】（安卓、苹果流程一致）
3. 手游游戏内：登录360账号—点击悬浮球—点击【安全中心】—点击【密保工具】—登录邮箱【立即设置】———进行绑定
特殊说明：
账号如有绑定其他密保信息（密保信息可用且激活状态），在绑定登录邮箱时，需先验证其他密保信息`,
        },
        {
            question: '如何更换登录邮箱',
            answer: `1. 浏览器登录i.360.cn进行更改：
官网PC版：
①登录360账号后—点击左侧常用操作中登录邮箱【修改】—进行更换（激活完成后即绑定成功）
②登录360账号后—点击账号安全—密保工具—登录邮箱【修改】—进行更换（激活完成后即绑定成功）
官网手机版：
登录360账号后—点击【我的密保】—登录邮箱【修改】—进行更换（激活完成后即绑定成功）（安卓、苹果流程一致）
2.手游游戏内：进入游戏后登录自己的360账号—点击悬浮球—点击【安全中心】—点击【密保工具】—登录邮箱【修改】—进行更换（激活完成后即绑定成功）
特殊说明：
账号如有绑定其他密保信息（密保信息可用且激活状态），在修改登录邮箱时，需先验证其他密保信息`,
        },
        {
            question: '怎样冻结/解冻账号',
            answer: `1. 账号下有绑定且激活的密保手机，并与安全卫士进行关联时：
账号卫士APP登录自己的360账号，— 点击【紧急冻结】— 点击【冻结账号】即可冻结成功解冻账号点击【解冻账号】即可解冻
2. 账号下绑定密保手机时，可发短信冻结/解冻账号：
①冻结：使用绑定手机号发送冻结指令 SDZH 至 10690133605 冻结帐号，冻结后的帐号无法登录  
②解冻：您可以使用绑定手机号发送解冻指令 JSZH 至 10690133605 解除冻结`,
        },
        {
            question: '冻结账号和解冻账号操作收费么',
            answer: `您发送冻结或解冻指令时，运营商会向您收取一条短信的费用，360是不会收取任何费用的，请知悉`,
        },
        {
            question: '从哪里设置实名认证（防沉迷）',
            answer: `浏览器登录wan.360.cn：登录自己的360账号后，点击【个人中心】—账号安全—防沉迷设置【立即设置】—填写姓名、身份证号后点击【确定】即可保存成功
游戏内：登录自己的360账号后，游戏页面会弹窗提示填写实名认证，填写姓名、身份证号后点击【确定】即可保存成功`,
        },
        {
            question: '如何注销账号',
            answer: `浏览器登录i.360.cn，登录360账号：
①点击【账号安全】—点击【账号服务】—点击【继续注销账号】
②选择验证方式—输入收到的验证码—账号注销确认（等待时间约20秒左右）
③点击【继续注销】—勾选“已阅读并同意《360账号注销协议》”—点击【同意并继续】
④勾选离开原因—填写联系邮箱，输入邮箱收到的验证码—页面提示申请提交成功
⑤结果会在4个工作日内发送到用户的联系邮箱（如果用户账号下未绑定密保信息，则点击【继续注销账号】后需要验证账号登录密码）`,
        },
        {
            question: '如何通过密保信息来找回密码',
            answer: `您可以复制此链接http://i.360.cn/findpwd/?account=到浏览器，进行找回密码操作`,
        },
        {
            question: '如何设置/修改账号用户名',
            answer: `您好，用户名一旦设置，无法修改
【注：360账号为360U开头的情况下，可以自行更改一次用户名】
设置方法如下：
1. 电脑登录 i.360.cn  登录自己的360账号后 — 点击页面右上角【未设置用户名】—进行设置用户名（用户名限制2-14个字符，支持中英文）
2. 手机登录 i.360.cn  登录自己的360账号后 — 点击【个人资料】—点击【用户名】进行设置—设置完毕后点击【保存】即可（安卓、苹果流程一致）`,
        },
        {
            question: '如何设置/修改昵称',
            answer: `您好，昵称不限制修改次数。
设置方法如下：
1、电脑登录 i.360.cn  登录自己的360账号后 — 点击头像右侧【设置昵称】进行设置（昵称限制2-14个字符，支持中英文）
2、手机登录 i.360.cn  登录自己的360账号后 — 点击【个人资料】—点击【昵称】进行设置—设置完毕后点击【保存】即可（安卓、苹果流程一致）`,
        },
    ]
}