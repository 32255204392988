import React from 'react'

// 客服热线
const Hotline = () => {
    return (
      <section>
         <style jsx>{`
             
         `}
         </style>
         
      </section>
    )
}
                          
export default Hotline