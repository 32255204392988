import Announcement from 'content/detailServices/announcement';
import HotQuiz from 'content/detailServices/hotQuiz';
import Myquiz from 'content/detailServices/myquiz';
import Faq from 'content/detailServices/faq';
import Vip from 'content/detailServices/vip';
import HotLine from 'content/detailServices/hotline';
import AccountProblems from 'content/detailServices/accountProblems';
import QuizDetail from 'content/detailServices/myquiz/quizDetail';
import ChangePhone from 'content/detailServices/accountProblems/changePhone';
import AccountContent from 'content/detailServices/accountProblems/accountContent';
import OnlineConsultation from 'content/detailServices/onlineConsultation';

const routes = [
    {
        path: '/myquiz',
        component: Myquiz,
        title: '我的问题',
        routes: [
            {
                path: '/myquiz/quizdetail',
                component: QuizDetail,
                title: '问题详情',
            },
            {
                path: '/myquiz/accountdetail',
                component: ChangePhone,
                title: '账号问题'
            },
        ]
    },
    {
        path: '/onlineconsultation',
        component: OnlineConsultation,
        title: '选择咨询游戏'
    },
    {
        path: '/faq',
        component: Faq,
        title: '自助服务'
    },
    {
        path: '/vip',
        component: Vip,
        title: '贵宾服务'
    },
    {
        path: '/hotline',
        component: HotLine,
        title: '客服热线',
    },
    {
        path: '/announcement',
        component: Announcement,
        title: '公告'
    },
    {
        path: '/hotquiz',
        component: HotQuiz,
        title: '热门问题'
    },
    {
        path: '/accountproblems',
        component: AccountProblems,
        title: '账号问题',
        routes: [
            {
                path: '/accountproblems/accountdetail',
                component: ChangePhone,
                title: '问题反馈'
            },
            {
                path: '/accountproblems/accountcontent',
                component: AccountContent,
                title: '问题反馈'
            },
        ]
    },
]

export default routes;