import React from 'react';
import { Icon } from 'antd';
import ReactDOM from 'react-dom';

export const formDialog = {
    dom: null, //被append的元素
 
    success (content = 'content', fn) {
        this.dom = document.createElement('div');
        const styles = {
            cover: {
                position: 'fixed',
                bottom: 0,
                top: 0,
                left: 0,
                right: 0,
                backgroundColor: 'black',
                opacity: 0.6,
                zIndex: '10000',
            },
            dialog: {
                width: '600px',
                height: '240px',
                position: 'fixed',
                top: '326px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'white',
                zIndex: '10001',
            },
            top: {
                height: '50px',
                // backgroundColor: '#e4004f',
                backgroundColor: '#d0021b',
                // lineHeight: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: 'white',
                padding: '0 16px',
            },
            tip: {
                fontSize: '16px',
                // marginLeft: '20px',
                color: 'white',
            },
            icon: {
                color: 'white',
                cursor: 'pointer',
            },
            content: {
                paddingTop: '40px',
                // height: '160px',
                width: '100%',
            },
            ct1: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            confirm: {
                width: '140px',
                height: '40px',
                // backgroundColor: '#e4004f',
                backgroundColor: '#d0021b',
                margin: '0 auto',
                color: 'white',
                fontSize: '14px',
                position: 'absolute',
                bottom: '30px',
                left: '50%',
                transform: 'translateX(-50%)',
            }
        }
        // JSX代码
        const JSXdom = (
            <section>
                <div style={styles.dialog}>
                    <div style={styles.top}>
                        <p style={styles.tip}>温馨提示</p>
                        <span style={styles.icon} onClick={() => this.toMyquiz(fn)}>
                            <Icon type="close" style={{ fontSize: '30px', color: 'white'}} />
                        </span>
                    </div>
                    <div style={styles.content}>
                        <div style={styles.ct1}>
                            <Icon type="check-circle" theme="filled" style={{ fontSize: '26px', color: '#94A25E' }} />
                            <span style={{marginLeft: '10px',fontSize: '16px',width: '80%'}}>{content}</span>
                        </div>
                        <button style={styles.confirm} onClick={() => this.toMyquiz(fn)}>确定</button>
                    </div>
                </div>
                <div style={styles.cover}></div>
            </section>
            
        );
 
        ReactDOM.render(JSXdom, this.dom);
        document.body.appendChild(this.dom);
    },

    toMyquiz(fn) {
        fn();
        this.close();
    },
 
    onCancel (onCancel) {
        (onCancel instanceof Function) && onCancel();
        this.close();
    },
 
    onOk (onOk) {
        (onOk instanceof Function) && onOk();
        this.close();
    },
 
    close () {
        this.dom && this.dom.remove();
    }
}
 
export default {
    dom: null, //被append的元素
 
    success (content = 'content', backgroundColor = 'white') {
        this.dom = document.createElement('div');
        const styles = {
            dialog: {
                position: 'fixed',
                top: '326px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor,
                padding: '8px 80px',
                zIndex: '1000',
                borderRadius: '4px',
            },
            content: {
                textAlign: 'center',
            }
        }
        // JSX代码
        const JSXdom = (
            <div style={styles.dialog}>
                <p style={styles.content}>{content}</p>
                {/* <div style={styles.mask} />
                <div style={styles.modalWrap}>
                    <div style={styles.modal}>
                        <h2>{title}</h2>
                        <p>{content}</p>
                        <div style={styles.btnGroup}>
                            <Button onClick={() => this.onCancel(onCancel)}>取消</Button>
                            <Button type="primary" onClick={() => this.onOk(onOk)}>确定</Button>
                        </div>
                    </div>
                </div> */}
            </div>
        );
 
        ReactDOM.render(JSXdom, this.dom);
        document.body.appendChild(this.dom);
        setTimeout(() => {
           this.close(); 
        },2000)
    },
 
    onCancel (onCancel) {
        (onCancel instanceof Function) && onCancel();
        this.close();
    },
 
    onOk (onOk) {
        (onOk instanceof Function) && onOk();
        this.close();
    },
 
    close () {
        this.dom && this.dom.remove();
    }
}
